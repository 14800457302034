.app__header{
    background-color: var(--color-white);
}

.app__header-h1{
    font-family: var(--font-base);
    color: var(--color-golden);
    letter-spacing: 0.04em;
    line-height: 110px;
    text-transform: uppercase;
    font-size: 90px;
}

.app__header p{
    color: var(--color-black);
}

.app__header-img img{
    width: 50%;
}

.app__header .app__pop img{
    width: 80%;
}

@media screen and (max-width: 850px) {
    .app__header-h1{
        font-size: 50px;
        line-height: 50px;
    }
}


.app__footerOverlay {
    width: 100%;
    height: 100%;
    z-index: -1;
    text-align: center;

    display: flex;
    flex-direction: column;
    position: absolute;
}

.app__footerOverlay-black {
    height: 35%;
    background: var(--color-black);
}

.app__footerOverlay-black h3{
    margin-top: -4rem;
    color: var(--color-golden);
    font-size: 35px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.app__footerOverlay-black p{
    padding-top: 1rem;
    color: var(--color-black);
    font-size: 20px;
    line-height: 2rem;
    font-family: var(--font-alt);
    background-color: white;
    padding-bottom: 1rem;
}

.app__footerOverlay-black h2{
    color: var(--color-golden);
    font-size: 20px;
    line-height: 3rem;
    letter-spacing: 4px;
    padding-top: 0.3rem;
}

.app__footerOverlay-img {
    height: 200%;
}
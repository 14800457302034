.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.popup-inner{
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #fff;
    text-align: center;
    border-radius: 2%;
}

.popup-inner .cls-btn{
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}
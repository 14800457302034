.app__chef-content{
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 5rem;
}

.app__wrapper_info{
    color: var(--color-black);
}

.app__chef-content p{
    padding-top: 1rem;
    color: var(--color-black);
}

.app__chef-content_quote{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.app__chef-content_quote img{
    width: 77px;
    height: 70px;
    margin: 0 1rem 0 0;
}

.app__chef-content_quote p{
    color: var(--color-black);
}

.app__chef-sign{
    width: 100%;
    margin-top: 3rem;
}
.app__chef-sign p{
    color: var(--color-black);
}

.app__chef-sign p:first-child{
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32;
    line-height: 41px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-black);
}

/*.app__chef-sign img{
    width: 250px;
    margin-top: 3rem;
}*/



@media screen and (max-width: 2000px) {
    .app__chef-sign img{
        width: 370px;
    }    
}

@media screen and (max-width: 450px) {
    .app__chef-sign img{
        width: 80%;
    } 
}
